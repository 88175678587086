// DEV CHANGES ON 17 DESEMBER 2024

export const deliveryType = [
  {
    label: 'Urgent',
    value: 'Urgent',
  },
  {
    label: 'SDS',
    value: 'SameDayService',
  },
  {
    label: 'Reguler',
    value: 'Reguler',
  },
];
export const districtCode = [
  {
    label: 'Jabodetabek',
    value: 'Jabodetabek',
  },
  {
    label: 'Jawa',
    value: 'Jawa',
  },
  {
    label: 'Luar Jawa',
    value: 'LuarJawa',
  },
  {
    label: 'Indonesia Timur',
    value: 'IndonesiaTimur',
  },
  {
    label: 'Jakarta',
    value: 'Jakarta',
  },
  {
    label: 'Bodetabek',
    value: 'Bodetabek',
  },
  {
    label: 'Kalimantan dan Sumatra',
    value: 'KalimantanDanSumatra',
  },
  {
    label: 'Sulawesi Maluku dan Bali',
    value: 'SulawesiMalukuDanBali',
  },
  {
    label: 'Papua NTT dan NTB',
    value: 'PapuaNTTDanNTB',
  },
  {
    label: 'Sumkal',
    value: 'Sumkal',
  },
  {
    label: 'Sumapa',
    value: 'Sumapa',
  },
  {
    label: 'Balinusa',
    value: 'Balinusa',
  },
];
export const ModeEmergency = [
  {
    label: 'Perbarui Amplop',
    value: 'UpdateAmplop',
  },
  {
    label: 'Perbarui Wilayah',
    value: 'UpdateArea',
  },
  {
    label: 'Perbarui Kurir',
    value: 'UpdateKurir',
  },
  {
    label: 'Perbarui No.Resi',
    value: 'UpdateNoResi',
  },
  {
    label: 'Perbarui Tanggal Kirim',
    value: 'UpdateTglKirim',
  },
  {
    label: 'Perbarui Tanggal Selesai',
    value: 'UpdateTglSampai',
  },
  {
    label: 'Perbarui Nama Penerima',
    value: 'UpdateReceiveName',
  },
  {
    label: 'Reset Data Delivery',
    value: 'RestoreData',
  },
  {
    label: 'No Scan',
    value: 'NoScan',
  },
  {
    label: 'Perbaharui Barcode',
    value: 'UpdateBarcode',
  },
  // {
  //   label: 'Perbaharui Tanggal Cycle',
  //   value: 'UpdateCycle',
  // },
  {
    label: 'Generate Koordinat',
    value: 'GenerateGeocode',
  },
];
export const HistoryType = [
  {
    label: 'Uploaded',
    value: 'Uploaded',
  },
  {
    label: 'ScanBarcode',
    value: 'ScanBarcode',
  },
  {
    label: 'WaybillPrinted',
    value: 'WaybillPrinted',
  },
  {
    label: 'ScanBranch',
    value: 'ScanBranch',
  },
  {
    label: 'ScanCourier',
    value: 'ScanCourier',
  },
  {
    label: 'DownloadCourier',
    value: 'DownloadCourier',
  },
  {
    label: 'WrongRoute',
    value: 'WrongRoute',
  },
  {
    label: 'Received',
    value: 'Received',
  },
  {
    label: 'Resend',
    value: 'Resend',
  },
  {
    label: 'UpdatedManual',
    value: 'UpdatedManual',
  },
  {
    label: 'PrintManifestCustomer',
    value: 'PrintManifestCustomer',
  },
  {
    label: 'UpdatedManualVendor',
    value: 'UpdatedManualVendor',
  },
];
export const CustomReportFormat = [
  {
    label: 'Value',
    value: 'ValueDefault',
  },
  {
    label: 'Sumber Tabel',
    value: 'SourceTabel',
  },
  {
    label: 'Lama Pengiriman',
    value: 'DeliveryTime',
  },
  {
    label: 'SLA Standart',
    value: 'SLAStandart',
  },
  {
    label: 'Denda Hari',
    value: 'DeliveryTimeSla',
  },
  {
    label: 'Total Dikirim',
    value: 'DeliveryCount',
  },
];
export const CustomReportFormatType = [
  {
    label: 'DateTime',
    value: 'DateTime',
  },
  {
    label: 'SuccessTime',
    value: 'SuccessTime',
  },
  {
    label: 'ReturnTime',
    value: 'ReturnTime',
  },
  {
    label: 'Status Pengiriman',
    value: 'DeliveryStatus',
  },
  {
    label: 'Kombinasi',
    value: 'CombineColumn',
  },
  {
    label: 'Imbuhan',
    value: 'Imbuhan',
  },
  {
    label: 'Status Tambahan',
    value: 'FreeTextStatus',
  },
  {
    label: 'Reverse',
    value: 'Reverse',
  },
  {
    label: 'Prioritas',
    value: 'Priority',
  },
];
export const FileFormat = [
  {
    label: 'EXCEL',
    value: 'xls',
  },
  {
    label: 'CSV',
    value: 'csv',
  },
];

export const EnumCustomerAccess = [
  {
    label: 'Menu Pelacakan',
    value: 1,
  },
  {
    label: 'Menu Laporan',
    value: 2,
  },
  {
    label: 'Menu Keluhan',
    value: 3,
  },
  {
    label: 'Menu Waybill Digital',
    value: 4,
  },
  {
    label: 'Pelacakan - Data Foto',
    value: 11,
  },
  {
    label: 'Pelacakan - Data TTD',
    value: 12,
  },
  {
    label: 'Pelacakan – Waybill Digital',
    value: 13,
  },
  {
    label: 'Pelacakan – Lokasi',
    value: 14,
  },
];
