/* eslint-disable no-mixed-operators */
import '../../../index.css';

import {
  Checkbox,
  Col,
  Form,
  Icon,
  InputNumber,
  Row,
  Select,
  Tooltip,
} from 'antd';
import {
  CustomReportFormat,
  CustomReportFormatType,
  HistoryType,
} from '../../../core/enumerable/General';
import React from 'react';

import ButtonMaterial from '../../../materials/ButtonMaterial';
import FormInputTextMaterial from '../../../materials/FormInputTextMaterial';
import ModalMaterial from '../../../materials/ModalMaterial';
import SpinMaterial from '../../../materials/SpinMaterial';

const { Option } = Select;

interface IProps {
  visible: any;
  jenisToggle: any;
  isLoading: any;
  LoadingCustomer: any;
  TypeLoadingCustomer: any;

  GetCustomerName: any;
  GetHeader: any;
  GetFormat: any;
  GetPosition: any;
  GetValue: any;
  GetValueDefault: any;
  GetSourceTable: any;
  GetFormatType: any;
  GetSourceColumn: any;
  GetSourceColumnSec: any;
  GetListSourceColumn: any;
  GetListSourceColumnSec: any;
  GetFormatColumn: any;
  GetCharLength: any;
  GetHistoryType: any;
  GetDeliveryTo: any;
  GetSubColumn: any;
  GetSubColumnSec: any;
  GetListSubColumn: any;
  GetListSubColumnSec: any;
  GetReplaceSuccess: any;
  GetReplaceReturn: any;
  GetReplaceOnProcess: any;
  GetReplaceOnHold: any;
  GetCheckBoxPetik: any;
  GetCheckBoxOthers: any;

  ValidateHeader: any;
  ValidatePosition: any;
  ValidateFormat: any;
  ValidateValueDefault: any;
  ValidateSourceTable: any;
  ValidateFormatType: any;
  ValidateSourceColumn: any;
  ValidateSourceColumnSec: any;
  ValidateFormatColumn: any;
  ValidateHistoryType: any;
  ValidateDeliveryTo: any;
  ValidateSubColumn: any;
  ValidateSubColumnSec: any;
  ValidateReplaceSuccess: any;
  ValidateReplaceReturn: any;
  ValidateReplaceOnProcess: any;
  ValidateReplaceOnHold: any;

  helpValidateHeader: any;
  helpValidatePosition: any;
  helpValidateFormat: any;
  helpValidateValueDefault: any;
  helpValidateSourceTable: any;
  helpValidateFormattype: any;
  helpValidateSourceColumn: any;
  helpValidateSourceColumnSec: any;
  helpValidateFormatColumn: any;
  helpValidateHistoryType: any;
  helpValidateDeliveryTo: any;
  helpValidateSubColumn: any;
  helpValidateSubColumnSec: any;
  helpValidateReplaceSuccess: any;
  helpValidateReplaceReturn: any;
  helpValidateReplaceOnProcess: any;
  helpValidateReplaceOnHold: any;

  handleChangeHeader: any;
  handleChangePosition: any;
  handleChangeFormat: any;
  handleChangeValue: any;
  handleChangeValueDefault: any;
  handleChangeSourceTable: any;
  handleChangeFormatType: any;
  handleChangeSourceColumn: any;
  handleChangeSourceColumnSec: any;
  handleChangeFormatColumn: any;
  handleChangeHistoryType: any;
  handleChangeDeliveryTo: any;
  handleChangeSubColumn: any;
  handleChangeSubColumnSec: any;
  handleChangeReplaceSuccess: any;
  handleChangeReplaceReturn: any;
  handleChangeReplaceOnProcess: any;
  handleChangeReplaceOnHold: any;
  handleChangeCheckBoxPetik: any;
  handleChangeCheckBoxOthers: any;
  handleChangeCharLength: any;

  handleGetListSourceColumn: any;
  handleGetListSourceColumnSec: any;
  handleGetListSubColumn: any;
  handleGetListSubColumnSec: any;

  handleBlurSourceTable: any;
  handleBlurFormat: any;
  handleBlurFormatType: any;
  handleBlurSourceColumn: any;
  handleBlurSourceColumnSec: any;
  handleBlurHistoryType: any;
  handleBlurDeliveryTo: any;
  handleBlurSubColumn: any;
  handleBlurSubColumnSec: any;

  handleResetFormat: any;
  handleCloseModal: any;
  handleSubmitCustomReport: any;
}
// TODO : need to be refactored!!!
export default function FormCustomDataReportCustomerComponent(props: IProps) {
  const {
    visible,
    jenisToggle,
    isLoading,
    LoadingCustomer,
    TypeLoadingCustomer,
    GetCustomerName,
    GetHeader,
    GetFormat,
    GetPosition,
    GetValueDefault,
    GetSourceTable,
    GetFormatType,
    GetSourceColumn,
    GetSourceColumnSec,
    GetListSourceColumn,
    GetListSourceColumnSec,
    GetFormatColumn,
    GetCharLength,
    GetHistoryType,
    GetDeliveryTo,
    GetSubColumn,
    GetSubColumnSec,
    GetListSubColumn,
    GetListSubColumnSec,
    GetReplaceSuccess,
    GetReplaceReturn,
    GetReplaceOnProcess,
    GetReplaceOnHold,
    GetCheckBoxPetik,
    GetCheckBoxOthers,
    ValidateHeader,
    ValidateFormat,
    ValidatePosition,
    ValidateSourceTable,
    ValidateSourceColumn,
    ValidateSourceColumnSec,
    ValidateFormatColumn,
    ValidateHistoryType,
    ValidateDeliveryTo,
    ValidateSubColumn,
    ValidateSubColumnSec,
    ValidateReplaceSuccess,
    ValidateReplaceReturn,
    ValidateReplaceOnProcess,
    ValidateReplaceOnHold,
    helpValidateHeader,
    helpValidatePosition,
    helpValidateFormat,
    helpValidateSourceTable,
    helpValidateSourceColumn,
    helpValidateSourceColumnSec,
    helpValidateFormatColumn,
    helpValidateHistoryType,
    helpValidateDeliveryTo,
    helpValidateSubColumn,
    helpValidateSubColumnSec,
    helpValidateReplaceSuccess,
    helpValidateReplaceReturn,
    helpValidateReplaceOnProcess,
    helpValidateReplaceOnHold,
    handleChangeHeader,
    handleChangePosition,
    handleChangeValueDefault,
    handleChangeSourceTable,
    handleChangeFormat,
    handleChangeFormatType,
    handleChangeSourceColumn,
    handleChangeSourceColumnSec,
    handleChangeFormatColumn,
    handleChangeHistoryType,
    handleChangeDeliveryTo,
    handleChangeSubColumn,
    handleChangeSubColumnSec,
    handleChangeReplaceSuccess,
    handleChangeReplaceReturn,
    handleChangeReplaceOnProcess,
    handleChangeReplaceOnHold,
    handleChangeCheckBoxPetik,
    handleChangeCheckBoxOthers,
    handleChangeCharLength,
    handleBlurSourceTable,
    handleBlurFormatType,
    handleBlurFormat,
    handleBlurSourceColumn,
    handleBlurSourceColumnSec,
    handleBlurHistoryType,
    handleBlurDeliveryTo,
    handleBlurSubColumn,
    handleBlurSubColumnSec,
    handleGetListSourceColumn,
    handleGetListSourceColumnSec,
    handleGetListSubColumn,
    handleGetListSubColumnSec,
    handleResetFormat,
    handleCloseModal,
    handleSubmitCustomReport,
  } = props;
  const checkboxSpan = {
    paddingLeft: 15,
    fontWeight: 500,
    color: '#969696',
  };
  const valiateBtn = () => {
    const coreValidate =
      GetFormat === null || GetHeader === null || ValidateHeader === 'error'
        ? true
        : false;
    const isSubColumn =
      GetSourceColumn === 'AdditionalData(STRING)' ||
      GetSourceColumn === 'CustomStatusCode(STRING)' ||
      GetSourceColumn === 'ShipToAddress(STRING)'
        ? true
        : false;
    const isSecSubColumn =
      GetSourceColumnSec === 'AdditionalData(STRING)' ||
      GetSourceColumnSec === 'CustomStatusCode(STRING)' ||
      GetSourceColumnSec === 'ShipToAddress(STRING)'
        ? true
        : false;

    const formatType =
      GetFormatType === 'DateTime' ||
      GetFormatType === 'SuccessTime' ||
      GetFormatType === 'ReturnTime' ||
      GetFormatType === 'Imbuhan'
        ? true
        : false;
    const validateReplaceStatus =
      !GetReplaceSuccess === false &&
      !GetReplaceReturn === false &&
      !GetReplaceOnProcess === false &&
      (GetSourceTable === 'DeliveryHistory'
        ? !GetReplaceOnHold === false
        : true)
        ? false
        : true;

    if (GetFormat === 'SourceTabel') {
      if (
        (GetSourceTable === 'Delivery' ||
          GetSourceTable === 'DeliveryHistory') &&
        (GetSourceColumn === 'DeliveryStatus' ||
          GetSourceColumn === 'DeliveryHistory') &&
        GetFormatType === 'DeliveryStatus'
      ) {
        return validateReplaceStatus;
      } else if (
        GetSourceTable === 'Delivery' &&
        isSubColumn === true &&
        formatType === true
      ) {
        //1 sumber kolom "true", format tipe "true"
        return coreValidate === false &&
          GetSubColumn !== null &&
          !GetFormatColumn === false
          ? false
          : true;
      } else if (
        GetSourceTable === 'Delivery' &&
        isSubColumn === false &&
        GetSourceColumn !== null &&
        formatType === true
      ) {
        //2 sumber kolom "false", format tipe "true"
        return coreValidate === false &&
          !GetFormatColumn === false &&
          GetSourceColumn !== null
          ? false
          : true;
      } else if (
        GetSourceTable === 'Delivery' &&
        isSubColumn === true &&
        formatType === false &&
        GetFormatType !== 'CombineColumn' &&
        GetFormatType !== 'Priority'
      ) {
        //3 sumber kolom "true", format tipe false"
        return coreValidate === false && GetSubColumn !== null ? false : true;
      } else if (
        GetSourceTable === 'Delivery' &&
        isSubColumn === false &&
        formatType === false &&
        GetFormatType !== 'CombineColumn'
      ) {
        //4 sumber kolom "false", format tipe false"
        return coreValidate === false && GetSourceColumn !== null
          ? false
          : true;
      } else if (
        GetSourceTable === 'Delivery' &&
        isSubColumn === true &&
        (GetFormatType === 'CombineColumn' || GetFormatType === 'Priority') &&
        isSecSubColumn === true
      ) {
        //5 sumber kolom "true", format tipe kombinasi, sumber sec kolom "true"
        return coreValidate === false && !GetFormatColumn === false
          ? false
          : GetFormatType === 'Priority'
          ? false
          : true;
      } else if (
        GetSourceTable === 'Delivery' &&
        isSubColumn === true &&
        GetFormatType === 'CombineColumn' &&
        isSecSubColumn === false
      ) {
        //6 sumber kolom "true", format tipe kombinasi, sumber sec kolom "false"
        return coreValidate === false &&
          GetSubColumn !== null &&
          !GetFormatColumn === false
          ? false
          : true;
      } else if (
        GetSourceTable === 'Delivery' &&
        isSubColumn === false &&
        GetFormatType === 'CombineColumn' &&
        isSecSubColumn === true
      ) {
        //7 sumber kolom "false", format tipe kombinasi, sumber sec kolom "true"
        return coreValidate === false &&
          GetSourceColumn !== null &&
          GetSubColumnSec !== null &&
          !GetFormatColumn === false
          ? false
          : true;
      } else if (
        GetSourceTable === 'Delivery' &&
        isSubColumn === false &&
        GetFormatType === 'CombineColumn' &&
        isSecSubColumn === false
      ) {
        //8 sumber kolom "false", format tipe kombinasi, sumber sec kolom "false"
        return coreValidate === false &&
          GetSourceColumn !== null &&
          GetSourceColumnSec !== null &&
          !GetFormatColumn === false
          ? false
          : true;
      } else {
        return true;
      }
    } else if (GetFormat === 'ValueDefault') {
      return coreValidate === false && !GetValueDefault === false
        ? false
        : true;
    } else {
      return coreValidate;
    }
  };

  return (
    <ModalMaterial
      className={''}
      width={'70%'}
      style={{}}
      teksTitle={
        (jenisToggle === 'AddCustomReportDataCustomer'
          ? 'TAMBAH'
          : jenisToggle === 'EditCustomReportDataCustomer'
          ? 'UBAH'
          : '') + ` KOLOM LAPORAN ${GetCustomerName}`
      }
      visible={
        (visible === true && jenisToggle === 'AddCustomReportDataCustomer') ||
        jenisToggle === 'EditCustomReportDataCustomer'
          ? true
          : false
      }
      onCancel={handleCloseModal}
      content={
        <SpinMaterial
          spinning={isLoading}
          style={{}}
          size={'large'}
          content={
            <div style={{ paddingTop: '20px' }}>
              <div style={{ marginRight: 20, marginLeft: 20 }}>
                <Row>
                  <Col span={12} style={{ paddingRight: '20px' }}>
                    <div>
                      <span>
                        <b>Judul Kolom</b>
                      </span>
                      <FormInputTextMaterial
                        validateType={true}
                        value={GetHeader}
                        disabled={false}
                        placeholder={'Contoh : WAYBILL'}
                        maxLength={100}
                        style={{ borderRadius: '10px' }}
                        onChange={handleChangeHeader}
                        onBlur={handleChangeHeader}
                        validatestatus={ValidateHeader}
                        help={helpValidateHeader}
                      />
                    </div>
                  </Col>
                  <Col span={12} style={{ paddingLeft: '20px' }}>
                    <div>
                      <span>
                        <b>Posisi</b>
                      </span>
                      <FormInputTextMaterial
                        validateType={true}
                        value={GetPosition}
                        className={''}
                        disabled={
                          jenisToggle === 'AddCustomReportDataCustomer'
                            ? true
                            : false
                        }
                        addonBefore={''}
                        placeholder={'Masukkan posisi'}
                        prefix={''}
                        maxLength={''}
                        style={{ borderRadius: '10px' }}
                        size=""
                        type={''}
                        onChange={handleChangePosition}
                        onBlur={handleChangePosition}
                        validatestatus={ValidatePosition}
                        help={helpValidatePosition}
                        onPressEnter={''}
                      />
                    </div>
                  </Col>
                </Row>
              </div>

              <div>
                <div style={{ marginRight: 20, marginLeft: 20 }}>
                  <Row>
                    <Col span={12} style={{ paddingRight: '20px' }}>
                      <div>
                        <span>
                          <b>Format</b>
                        </span>
                        <Form.Item
                          validateStatus={ValidateFormat}
                          help={helpValidateFormat}
                        >
                          <Select
                            style={{ width: '100%' }}
                            disabled={false}
                            placeholder="Pilih Jenis Format"
                            onSelect={handleResetFormat}
                            optionFilterProp="children"
                            value={
                              GetFormat === null ? (
                                <span style={{ color: '#969696' }}>
                                  Pilih jenis format
                                </span>
                              ) : (
                                GetFormat
                              )
                            }
                            onChange={handleChangeFormat}
                            onBlur={handleBlurFormat}
                          >
                            {CustomReportFormat.map((data: any, index: any) => (
                              <Option key={index} value={data.value}>
                                {data.label}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    </Col>
                    <Col span={12} style={{ paddingLeft: '20px' }}>
                      <div>
                        <span>
                          <b>Value</b>
                        </span>
                        <FormInputTextMaterial
                          validateType={true}
                          value={GetValueDefault}
                          className={''}
                          disabled={GetFormat === 'ValueDefault' ? false : true}
                          addonBefore={''}
                          placeholder={'Contoh : RDS'}
                          prefix={''}
                          maxLength={30}
                          style={{ borderRadius: '10px' }}
                          size=""
                          type={''}
                          onChange={handleChangeValueDefault}
                          onBlur={handleChangeValueDefault}
                          validatestatus={''}
                          help={''}
                          onPressEnter={''}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>

                {GetFormat === 'SourceTabel' ? (
                  <div style={{}}>
                    <hr
                      style={{
                        display: 'block',
                        height: '1px',
                        border: '0',
                        borderTop: '1px solid #ccc',
                        margin: '0',
                        padding: '0',
                      }}
                    />
                    <div
                      style={{
                        marginRight: 20,
                        marginLeft: 20,
                        paddingTop: '17px',
                      }}
                    >
                      <Row>
                        <Col span={12} style={{ paddingRight: '20px' }}>
                          <div>
                            <span>
                              <b>Sumber Tabel</b>
                            </span>
                            <Form.Item
                              validateStatus={ValidateSourceTable}
                              help={helpValidateSourceTable}
                            >
                              <Select
                                style={{
                                  width: '100%',
                                  borderRadius: '10px',
                                }}
                                onSelect={handleChangeSourceTable}
                                optionFilterProp="children"
                                showSearch
                                value={
                                  GetSourceTable === null ? (
                                    <span style={{ color: '#969696' }}>
                                      Pilih sumber tabel
                                    </span>
                                  ) : (
                                    GetSourceTable
                                  )
                                }
                                onChange={handleChangeSourceTable}
                                onBlur={handleBlurSourceTable}
                              >
                                <Option value="Delivery">Master Data</Option>
                                <Option value="DeliveryHistory">
                                  Riwayat Pengiriman
                                </Option>
                              </Select>
                            </Form.Item>
                          </div>
                        </Col>
                        <Col span={12} style={{ paddingLeft: '20px' }}>
                          <div>
                            <span>
                              <b>Format Tipe</b>
                            </span>
                            <Row>
                              <Col span={14}>
                                <Form.Item validateStatus={''} help={''}>
                                  <Select
                                    style={{
                                      width: '100%',
                                      borderRadius: '10px',
                                    }}
                                    onSelect={handleChangeFormatType}
                                    optionFilterProp="children"
                                    showSearch
                                    value={
                                      GetFormatType === null ? (
                                        <span style={{ color: '#969696' }}>
                                          Pilih tipe format
                                        </span>
                                      ) : (
                                        GetFormatType
                                      )
                                    }
                                    onChange={handleChangeFormatType}
                                    onBlur={handleBlurFormatType}
                                  >
                                    <Option value="Empty">
                                      <span style={{ color: '#969696' }}>
                                        Pilih format tipe
                                      </span>
                                    </Option>
                                    {CustomReportFormatType.filter(value => {
                                      if (
                                        GetSourceTable === 'DeliveryHistory'
                                      ) {
                                        return value.value === 'DeliveryStatus';
                                      } else {
                                        return value;
                                      }
                                    }).map((data: any, index: any) => (
                                      <Option key={index} value={data.value}>
                                        {data.label}
                                      </Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col span={10}>
                                <div style={{ marginTop: 8, marginLeft: 32 }}>
                                  <Checkbox
                                    onChange={handleChangeCheckBoxPetik}
                                    checked={GetCheckBoxPetik}
                                    disabled={
                                      GetSourceTable === 'DeliveryHistory'
                                    }
                                  />
                                  <span style={checkboxSpan}>
                                    Tambahkan Petik
                                  </span>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </div>

                    <div style={{ marginRight: 20, marginLeft: 20 }}>
                      <Row>
                        <Col span={12} style={{ paddingRight: '20px' }}>
                          <div>
                            <span>
                              <b>Sumber Kolom</b>
                            </span>
                            {GetSourceColumn === 'ReceiverTitle(STRING)' ||
                            GetSourceColumn === 'ReturnedReason(STRING)' ||
                            GetSourceColumn === 'ReturnedReason1(STRING)' ||
                            GetSourceColumn === 'ReturnedReason2(STRING)' ||
                            GetSourceColumn === 'ReturnedReason3(STRING)' ? (
                              <Row>
                                <Col span={12}>
                                  <Form.Item
                                    validateStatus={ValidateSourceColumn}
                                    help={helpValidateSourceColumn}
                                  >
                                    <Select
                                      loading={
                                        LoadingCustomer === true &&
                                        TypeLoadingCustomer ===
                                          'selectColumnName'
                                          ? true
                                          : false
                                      }
                                      disabled={
                                        GetSourceTable === null
                                          ? true
                                          : GetSourceTable === 'Empty'
                                          ? true
                                          : false
                                      }
                                      style={{ width: '100%' }}
                                      optionFilterProp="children"
                                      showSearch
                                      value={
                                        GetSourceColumn === null ? (
                                          <span style={{ color: '#969696' }}>
                                            Pilih sumber kolom
                                          </span>
                                        ) : (
                                          GetSourceColumn
                                        )
                                      }
                                      onChange={handleChangeSourceColumn}
                                      onFocus={handleGetListSourceColumn}
                                      onSelect={handleChangeSourceColumn}
                                      onBlur={handleBlurSourceColumn}
                                    >
                                      {GetListSourceColumn.map(
                                        (data: any, index: any) => (
                                          <Option key={index} value={data}>
                                            {data}
                                          </Option>
                                        )
                                      )}
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col span={12}>
                                  <div style={{ marginTop: 8, marginLeft: 32 }}>
                                    <Checkbox
                                      onChange={handleChangeCheckBoxOthers}
                                      checked={GetCheckBoxOthers}
                                    />
                                    <span style={checkboxSpan}>
                                      Tampilkan Teks Lain-lain
                                    </span>
                                  </div>
                                </Col>
                              </Row>
                            ) : (
                              <Form.Item
                                validateStatus={ValidateSourceColumn}
                                help={helpValidateSourceColumn}
                              >
                                <Select
                                  loading={
                                    LoadingCustomer === true &&
                                    TypeLoadingCustomer === 'selectColumnName'
                                      ? true
                                      : false
                                  }
                                  disabled={
                                    GetSourceTable === null
                                      ? true
                                      : GetSourceTable === 'Empty'
                                      ? true
                                      : false
                                  }
                                  style={{ width: '100%' }}
                                  optionFilterProp="children"
                                  showSearch
                                  value={
                                    GetSourceColumn === null ? (
                                      <span style={{ color: '#969696' }}>
                                        Pilih sumber kolom
                                      </span>
                                    ) : (
                                      GetSourceColumn
                                    )
                                  }
                                  onChange={handleChangeSourceColumn}
                                  onFocus={handleGetListSourceColumn}
                                  onSelect={handleChangeSourceColumn}
                                  onBlur={handleBlurSourceColumn}
                                >
                                  {GetSourceTable === 'DeliveryHistory' ? (
                                    <Option value={'DeliveryHistory'}>
                                      Riwayat Pengiriman(Pickup, Kirim, Selesai)
                                    </Option>
                                  ) : (
                                    GetListSourceColumn.map(
                                      (data: any, index: any) => (
                                        <Option key={index} value={data}>
                                          {data}
                                        </Option>
                                      )
                                    )
                                  )}
                                </Select>
                              </Form.Item>
                            )}
                          </div>
                        </Col>
                        <Col span={12} style={{ paddingLeft: '20px' }}>
                          {(GetSourceTable === 'Delivery' ||
                            GetSourceTable === 'DeliveryHistory') &&
                          (GetSourceColumn === 'DeliveryStatus' ||
                            GetSourceColumn === 'DeliveryHistory') &&
                          GetFormatType === 'DeliveryStatus' ? (
                            <div>
                              <span>
                                <b>Replace Success</b>
                              </span>
                              <FormInputTextMaterial
                                validateType={true}
                                value={GetReplaceSuccess}
                                className={''}
                                disabled={false}
                                addonBefore={''}
                                placeholder={'Diterima'}
                                prefix={''}
                                maxLength={''}
                                style={{ borderRadius: '10px' }}
                                size=""
                                type={''}
                                onChange={handleChangeReplaceSuccess}
                                onBlur={handleChangeReplaceSuccess}
                                validatestatus={ValidateReplaceSuccess}
                                help={helpValidateReplaceSuccess}
                                onPressEnter={''}
                              />
                            </div>
                          ) : GetFormatType === 'CombineColumn' ||
                            GetFormatType === 'Priority' ? (
                            <div>
                              <span>
                                <b>Sumber Kolom 2</b>
                              </span>
                              <Form.Item
                                validateStatus={ValidateSourceColumnSec}
                                help={helpValidateSourceColumnSec}
                              >
                                <Select
                                  loading={
                                    LoadingCustomer === true &&
                                    TypeLoadingCustomer ===
                                      'selectColumnNameSec'
                                      ? true
                                      : false
                                  }
                                  disabled={
                                    GetSourceTable === null
                                      ? true
                                      : GetSourceTable === 'Empty'
                                      ? true
                                      : false
                                  }
                                  style={{ width: '100%' }}
                                  optionFilterProp="children"
                                  showSearch
                                  value={
                                    GetSourceColumnSec === null ? (
                                      <span style={{ color: '#969696' }}>
                                        Pilih sumber kolom 2
                                      </span>
                                    ) : (
                                      GetSourceColumnSec
                                    )
                                  }
                                  onChange={handleChangeSourceColumnSec}
                                  onFocus={handleGetListSourceColumnSec}
                                  onSelect={handleChangeSourceColumnSec}
                                  onBlur={handleBlurSourceColumnSec}
                                >
                                  {GetListSourceColumnSec.map(
                                    (data: any, index: any) => (
                                      <Option key={index} value={data}>
                                        {data}
                                      </Option>
                                    )
                                  )}
                                </Select>
                              </Form.Item>
                            </div>
                          ) : (
                            <div>
                              <span>
                                <b style={{ paddingRight: '8px' }}>
                                  Format Kolom
                                </b>
                                <Tooltip
                                  title={
                                    <div>
                                      <div>
                                        <span>Contoh :</span>
                                      </div>
                                      <div>
                                        <span>{'*DateTime = MM-DD-YYYY'}</span>
                                      </div>
                                      <div>
                                        <span>
                                          {'*Fungsi = ToUpper(<value>)'}
                                        </span>
                                      </div>
                                      <div>
                                        <span>{'*Imbuhan = ‘<value>'}</span>
                                      </div>
                                    </div>
                                  }
                                >
                                  <Icon type="question-circle-o" />
                                </Tooltip>
                              </span>
                              <FormInputTextMaterial
                                validateType={true}
                                value={GetFormatColumn}
                                className={''}
                                disabled={
                                  GetFormatType === null
                                    ? true
                                    : GetFormatType === 'Empty'
                                    ? true
                                    : GetFormatType === 'CodeStatusReciver'
                                    ? true
                                    : GetFormatType === 'DeliveryStatus'
                                    ? true
                                    : GetFormatType === 'FreeTextStatus'
                                    ? true
                                    : GetFormatType === 'Reverse'
                                    ? true
                                    : false
                                }
                                addonBefore={''}
                                placeholder={
                                  GetFormatType === 'DateTime'
                                    ? 'Contoh : MM-DD-YYYY'
                                    : GetFormatType === 'Fungsi'
                                    ? 'Contoh : ToUpper(<value>)'
                                    : GetFormatType === 'Imbuhan'
                                    ? 'Contoh : ‘<value>'
                                    : 'Masukan format kolom bila perlu'
                                }
                                style={{ borderRadius: '10px' }}
                                onChange={handleChangeFormatColumn}
                                onBlur={handleChangeFormatColumn}
                                validatestatus={ValidateFormatColumn}
                                help={helpValidateFormatColumn}
                              />
                            </div>
                          )}
                        </Col>
                      </Row>
                    </div>

                    <div style={{ marginRight: 20, marginLeft: 20 }}>
                      <Row>
                        <Col span={12} style={{ paddingRight: '20px' }}>
                          {GetSourceTable === 'DeliveryStatus' ? (
                            <div>
                              <span>
                                <b>Jenis Riwayat</b>
                              </span>
                              <Form.Item
                                validateStatus={ValidateHistoryType}
                                help={helpValidateHistoryType}
                              >
                                <Select
                                  style={{
                                    width: '100%',
                                    borderRadius: '10px',
                                  }}
                                  onSelect={handleChangeHistoryType}
                                  optionFilterProp="children"
                                  showSearch
                                  value={
                                    GetHistoryType === null ? (
                                      <span style={{ color: '#969696' }}>
                                        Pilih jenis Riwayat
                                      </span>
                                    ) : (
                                      GetHistoryType
                                    )
                                  }
                                  disabled={
                                    GetSourceTable === null ? true : false
                                  }
                                  onChange={handleChangeHistoryType}
                                  onBlur={handleBlurHistoryType}
                                >
                                  {HistoryType.map((data: any, index: any) => (
                                    <Option key={index} value={data.value}>
                                      {data.label}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </div>
                          ) : GetSourceTable === 'Delivery' ? (
                            <div>
                              <span>
                                <b>Tambah Sub Kolom</b>
                              </span>
                              <Form.Item
                                validateStatus={ValidateSubColumn}
                                help={helpValidateSubColumn}
                              >
                                <Select
                                  loading={
                                    LoadingCustomer === true &&
                                    TypeLoadingCustomer === 'selectSubColumn'
                                      ? true
                                      : false
                                  }
                                  disabled={
                                    GetSourceColumn !==
                                      'ShipToAddress(STRING)' &&
                                    GetSourceColumn !==
                                      'CustomStatusCode(STRING)' &&
                                    GetSourceColumn !==
                                      'AdditionalData(STRING)' &&
                                    GetSourceColumn !==
                                      'AdditionalAddress(STRING)' &&
                                    GetSourceColumn !==
                                      'ReceiverLocation(STRING)' &&
                                    GetSourceColumn !==
                                      'ReceiverLocationManual(STRING)' &&
                                    GetSourceColumn !== 'Branch' &&
                                    GetSourceColumn !== 'Courier'
                                      ? true
                                      : false
                                  }
                                  style={{ width: '100%' }}
                                  optionFilterProp="children"
                                  showSearch
                                  value={
                                    GetSubColumn === null ||
                                    GetSubColumn === 'Empty' ? (
                                      <span style={{ color: '#969696' }}>
                                        Pilih sub kolom
                                      </span>
                                    ) : (
                                      GetSubColumn
                                    )
                                  }
                                  onChange={handleChangeSubColumn}
                                  onFocus={handleGetListSubColumn}
                                  onSelect={handleChangeSubColumn}
                                  onBlur={handleBlurSubColumn}
                                >
                                  {GetListSubColumn.map(
                                    (data: any, index: any) => (
                                      <Option key={index} value={data}>
                                        {data}
                                      </Option>
                                    )
                                  )}
                                </Select>
                              </Form.Item>
                            </div>
                          ) : null}
                        </Col>
                        <Col span={12} style={{ paddingLeft: '20px' }}>
                          {(GetSourceTable === 'Delivery' ||
                            GetSourceTable === 'DeliveryHistory') &&
                          (GetSourceColumn === 'DeliveryStatus' ||
                            GetSourceColumn === 'DeliveryHistory') &&
                          GetFormatType === 'DeliveryStatus' ? (
                            <div>
                              <span>
                                <b>Replace Return</b>
                              </span>
                              <FormInputTextMaterial
                                validateType={true}
                                value={GetReplaceReturn}
                                className={''}
                                disabled={false}
                                addonBefore={''}
                                placeholder={'Dikembalikan'}
                                prefix={''}
                                maxLength={''}
                                style={{ borderRadius: '10px' }}
                                size=""
                                type={''}
                                onChange={handleChangeReplaceReturn}
                                onBlur={handleChangeReplaceReturn}
                                validatestatus={ValidateReplaceReturn}
                                help={helpValidateReplaceReturn}
                                onPressEnter={''}
                              />
                            </div>
                          ) : GetFormatType === 'CombineColumn' ||
                            GetFormatType === 'Priority' ? (
                            <div>
                              <span>
                                <b>Tambah Sub Kolom 2</b>
                              </span>
                              <Form.Item
                                validateStatus={ValidateSubColumnSec}
                                help={helpValidateSubColumnSec}
                              >
                                <Select
                                  loading={
                                    LoadingCustomer === true &&
                                    TypeLoadingCustomer === 'selectSubColumnSec'
                                      ? true
                                      : false
                                  }
                                  disabled={
                                    GetSourceColumnSec !==
                                      'ShipToAddress(STRING)' &&
                                    GetSourceColumn !==
                                      'CustomStatusCode(STRING)' &&
                                    GetSourceColumnSec !==
                                      'AdditionalData(STRING)' &&
                                    GetSourceColumnSec !==
                                      'AdditionalAddress(STRING)' &&
                                    GetSourceColumnSec !==
                                      'ReceiverLocation(STRING)' &&
                                    GetSourceColumnSec !==
                                      'ReceiverLocationManual(STRING)' &&
                                    GetSourceColumnSec !== 'Branch' &&
                                    GetSourceColumnSec !== 'Courier'
                                      ? true
                                      : false
                                  }
                                  style={{ width: '100%' }}
                                  optionFilterProp="children"
                                  showSearch
                                  value={
                                    GetSubColumnSec === null ||
                                    GetSubColumnSec === 'Empty' ? (
                                      <span style={{ color: '#969696' }}>
                                        Pilih sub kolom 2
                                      </span>
                                    ) : (
                                      GetSubColumnSec
                                    )
                                  }
                                  onChange={handleChangeSubColumnSec}
                                  onFocus={handleGetListSubColumnSec}
                                  onSelect={handleChangeSubColumnSec}
                                  onBlur={handleBlurSubColumnSec}
                                >
                                  {GetListSubColumnSec.map(
                                    (data: any, index: any) => (
                                      <Option key={index} value={data}>
                                        {data}
                                      </Option>
                                    )
                                  )}
                                </Select>
                              </Form.Item>
                            </div>
                          ) : null}
                        </Col>
                      </Row>
                    </div>

                    <div style={{ marginRight: 20, marginLeft: 20 }}>
                      <Row>
                        <Col span={12} style={{ paddingRight: '20px' }}>
                          {GetSourceTable === 'Delivery' &&
                            GetFormatType === 'Priority' && (
                              <div>
                                <span>
                                  <b style={{ paddingRight: '8px' }}>
                                    Format Kolom
                                  </b>
                                  <Tooltip
                                    title={
                                      <div>
                                        <div>
                                          <span>Contoh :</span>
                                        </div>
                                        <div>
                                          <span>
                                            {'*DateTime = MM-DD-YYYY'}
                                          </span>
                                        </div>
                                        <div>
                                          <span>
                                            {'*Fungsi = ToUpper(<value>)'}
                                          </span>
                                        </div>
                                        <div>
                                          <span>{'*Imbuhan = ‘<value>'}</span>
                                        </div>
                                      </div>
                                    }
                                  >
                                    <Icon type="question-circle-o" />
                                  </Tooltip>
                                </span>
                                <FormInputTextMaterial
                                  validateType={true}
                                  value={GetFormatColumn}
                                  className={''}
                                  disabled={
                                    GetFormatType === null
                                      ? true
                                      : GetFormatType === 'Empty'
                                      ? true
                                      : GetFormatType === 'CodeStatusReciver'
                                      ? true
                                      : GetFormatType === 'DeliveryStatus'
                                      ? true
                                      : GetFormatType === 'FreeTextStatus'
                                      ? true
                                      : GetFormatType === 'Reverse'
                                      ? true
                                      : false
                                  }
                                  addonBefore={''}
                                  placeholder={
                                    GetFormatType === 'DateTime'
                                      ? 'Contoh : MM-DD-YYYY'
                                      : GetFormatType === 'Fungsi'
                                      ? 'Contoh : ToUpper(<value>)'
                                      : GetFormatType === 'Imbuhan'
                                      ? 'Contoh : ‘<value>'
                                      : 'Masukan format kolom bila perlu'
                                  }
                                  style={{ borderRadius: '10px' }}
                                  onChange={handleChangeFormatColumn}
                                  onBlur={handleChangeFormatColumn}
                                  validatestatus={ValidateFormatColumn}
                                  help={helpValidateFormatColumn}
                                />
                              </div>
                            )}
                          {GetSourceTable === 'DeliveryStatus' ? (
                            <div>
                              <span>
                                <b>Pengiriman Ke</b>
                              </span>
                              <Form.Item
                                validateStatus={ValidateDeliveryTo}
                                help={helpValidateDeliveryTo}
                              >
                                <Select
                                  style={{
                                    width: '100%',
                                    borderRadius: '10px',
                                  }}
                                  onSelect={handleChangeDeliveryTo}
                                  optionFilterProp="children"
                                  showSearch
                                  value={
                                    GetDeliveryTo === null ? (
                                      <span style={{ color: '#969696' }}>
                                        Pilih pengiriman ke
                                      </span>
                                    ) : (
                                      GetDeliveryTo
                                    )
                                  }
                                  disabled={
                                    GetHistoryType === null ? true : false
                                  }
                                  onChange={handleChangeDeliveryTo}
                                  onBlur={handleBlurDeliveryTo}
                                >
                                  <Option value={1}>1</Option>
                                  <Option value={2}>2</Option>
                                  <Option value={3}>3</Option>
                                </Select>
                              </Form.Item>
                            </div>
                          ) : null}
                        </Col>
                        <Col span={12} style={{ paddingLeft: '20px' }}>
                          {(GetSourceTable === 'Delivery' ||
                            GetSourceTable === 'DeliveryHistory') &&
                          (GetSourceColumn === 'DeliveryStatus' ||
                            GetSourceColumn === 'DeliveryHistory') &&
                          GetFormatType === 'DeliveryStatus' ? (
                            <div>
                              <span>
                                <b>Replace Onprocess</b>
                              </span>
                              <FormInputTextMaterial
                                validateType={true}
                                value={GetReplaceOnProcess}
                                className={''}
                                disabled={false}
                                addonBefore={''}
                                placeholder={'Diproses'}
                                prefix={''}
                                maxLength={''}
                                style={{ borderRadius: '10px' }}
                                size=""
                                type={''}
                                onChange={handleChangeReplaceOnProcess}
                                onBlur={handleChangeReplaceOnProcess}
                                validatestatus={ValidateReplaceOnProcess}
                                help={helpValidateReplaceOnProcess}
                                onPressEnter={''}
                              />
                            </div>
                          ) : GetFormatType === 'CombineColumn' ? (
                            <div>
                              <span>
                                <b style={{ paddingRight: '8px' }}>
                                  Format Kolom
                                </b>
                                <Tooltip
                                  title={
                                    <div>
                                      <div>
                                        <span>Contoh :</span>
                                      </div>
                                      <div>
                                        <span>{'*DateTime = MM-DD-YYYY'}</span>
                                      </div>
                                      <div>
                                        <span>
                                          {'*Fungsi = ToUpper(<value>)'}
                                        </span>
                                      </div>
                                      <div>
                                        <span>{'*Imbuhan = ‘<value>'}</span>
                                      </div>
                                      <div>
                                        <span>
                                          {'*Kombinasi = <value1>(<value2>)'}
                                        </span>
                                      </div>
                                    </div>
                                  }
                                >
                                  <Icon type="question-circle-o" />
                                </Tooltip>
                              </span>
                              <FormInputTextMaterial
                                validateType={true}
                                value={GetFormatColumn}
                                className={''}
                                disabled={
                                  GetFormatType === null
                                    ? true
                                    : GetFormatType === 'Empty'
                                    ? true
                                    : GetFormatType === 'CodeStatusReciver'
                                    ? true
                                    : GetFormatType === 'DeliveryStatus'
                                    ? true
                                    : GetFormatType === 'FreeTextStatus'
                                    ? true
                                    : GetFormatType === 'Reverse'
                                    ? true
                                    : false
                                }
                                addonBefore={''}
                                placeholder={
                                  GetFormatType === 'DateTime'
                                    ? 'Contoh : MM-DD-YYYY'
                                    : GetFormatType === 'Fungsi'
                                    ? 'Contoh : ToUpper(<value>)'
                                    : GetFormatType === 'Imbuhan'
                                    ? 'Contoh : "<value>'
                                    : GetFormatType === 'CombineColumn' ||
                                      GetFormatType === 'Priority'
                                    ? 'Contoh : <value1>(<value2>)'
                                    : 'Masukan format kolom bila perlu'
                                }
                                prefix={''}
                                maxLength={''}
                                style={{ borderRadius: '10px' }}
                                size=""
                                type={''}
                                onChange={handleChangeFormatColumn}
                                onBlur={handleChangeFormatColumn}
                                validatestatus={ValidateFormatColumn}
                                help={helpValidateFormatColumn}
                                onPressEnter={''}
                              />
                            </div>
                          ) : null}

                          {GetSourceTable === 'DeliveryHistory' &&
                            GetSourceColumn === 'DeliveryHistory' &&
                            GetFormatType === 'DeliveryStatus' && (
                              <div>
                                <span>
                                  <b>Replace Onhold</b>
                                </span>
                                <FormInputTextMaterial
                                  validateType={true}
                                  value={GetReplaceOnHold}
                                  className={''}
                                  disabled={false}
                                  addonBefore={''}
                                  placeholder={'Ditunda'}
                                  prefix={''}
                                  maxLength={''}
                                  style={{ borderRadius: '10px' }}
                                  size=""
                                  type={''}
                                  onChange={handleChangeReplaceOnHold}
                                  onBlur={handleChangeReplaceOnHold}
                                  validatestatus={ValidateReplaceOnHold}
                                  help={helpValidateReplaceOnHold}
                                  onPressEnter={''}
                                />
                              </div>
                            )}

                          <span>
                            <b style={{ paddingRight: '8px' }}>
                              Panjang Karakter
                            </b>
                            <Tooltip
                              title={'Untuk menentukan panjang karakter'}
                            >
                              <Icon type="question-circle-o" />
                            </Tooltip>
                          </span>
                          <Form.Item>
                            <InputNumber
                              min={1}
                              max={999}
                              // defaultValue={1}
                              placeholder={'Contoh : 20'}
                              style={{
                                borderRadius: '10px',
                                minWidth: '100px',
                                width: '100%',
                              }}
                              onChange={handleChangeCharLength}
                              value={GetCharLength}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  </div>
                ) : null}
              </div>

              <div style={{ textAlign: 'right', marginRight: 20 }}>
                <ButtonMaterial
                  disabled={valiateBtn()}
                  handleSubmit={handleSubmitCustomReport}
                  teksButton={'Simpan'}
                  size={''}
                  shape={'default'}
                  className={''}
                  type={'primary'}
                  style={
                    valiateBtn() === true
                      ? { height: '35px', borderRadius: '12px' }
                      : {
                          colorFont: '#11BEFF',
                          height: '35px',
                          borderRadius: '12px',
                          background: '#11BEFF',
                          borderColor: '#11BEFF',
                        }
                  }
                  icon={''}
                />
              </div>
            </div>
          }
        />
      }
    />
  );
}
